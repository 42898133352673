$color_white: #fff;
$color_2: #212529;
$color_3: #e74c3c;
$color_4: #58bae4;

.data-inv-info {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.data-inv-group-1,
.data-inv-group-2 {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	padding: 10px;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}

.data-inv-group-1 {
	width: 50%;
}

.data-inv-group-2 {
	width: 45%;
}

.section-inv-header {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	background: $color_4;
	height: 34px;
	padding: 0 10px;
	border-radius: 4px 4px 0 0;

	h3.bill-header-title {
		background-color: $color_white;
		font-size: 18px;
		width: 200px;
		color: #58bae4;
		line-height: 34px;
		margin: 0 12vw 0 auto;
		text-align: center;
	}
}

.data-details-4 {
	.data-label {
		width: 35%;
		font-size: 16px;
		font-weight: 500;
		line-height: 18px;
	}

	.data-semicolon {
		width: 10%;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		justify-content: center;
	}

	.data-value {
		width: 55%;
		text-align: right;
		font-size: 14px;
		padding-right: 10px;
		word-wrap: break-word;
	}

	.rej-reason-box {
		align-items: flex-start;

		.data-value {
			text-align: left;
			line-height: 18px;
			display: flex;
			justify-content: end;
		}
	}
}

.data-details-3 {
	width: 30%;

	.data-label {
		text-align: right;
		width: 45%;
		margin-right: 1rem;
		font-size: 16px;
		font-weight: 500;
	}

	.data-semicolon {
		width: 5%;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-box-pack: end;
		-ms-flex-pack: end;
		justify-content: end;
	}

	.data-value {
		padding-right: 10px;
		width: 55%;
		text-align: right;
		-webkit-box-flex: 1;
		-ms-flex-positive: 1;
		flex-grow: 1;
		font-size: 14px;
		word-wrap: break-word;
		overflow: hidden;
		-o-text-overflow: ellipsis;
		text-overflow: ellipsis;
	}

	.view-total-amount {
		background: $color_4;
		color: $color_white;
		width: auto;
		height: 38px;
	}
}

.GST-bill-width {
	width: 45%;
}

.inv-gst-tbl-cont {
	.gst-cont-label {
		text-align: right;
		width: 40%;
		color: $color_2;
		font-size: 16px;
		font-weight: 500;
	}

	.gst-cont-semicolumn {
		text-align: center;
		width: 5%;
		margin: 0 10px;
	}

	.gst-cont-input {
		width: 55%;

		input {
			-ms-flex-item-align: end;
			align-self: flex-end;
		}
	}
}

.view-footer-border {
	border: 2px solid $color_4;
}

.view-total-amount {
	background: $color_4;
	height: 38px;

	span {
		font-weight: 500 !important;
		font-size: 16px !important;
	}
}

.payment-details-container {
	.data-label {
		font-size: 14px;
		font-weight: 600;
		width: 35%;
		text-align: left;
	}

	.data-semicolon {
		width: 10%;
	}

	.data-value {
		width: 50%;
		padding-right: 10px;
		font-size: 14px;
		-o-text-overflow: ellipsis;
		text-overflow: ellipsis;
		word-wrap: break-word;
		max-width: 30ch;
	}
}

.TDS-error {
	font-size: 11px;
	color: $color_3;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: end;
	-ms-flex-pack: end;
	justify-content: end;
}

.validation-error {
	position: absolute;
	font-size: 11px;
	color: $color_3;
}

.finance-view-accordian {
	padding: 20px;
}

.tr-highlight {
	background-color: #d3d3d3!important;
}

.cstm-bill-list .table-theme-1 tr:nth-of-type(even) {
	background-color: initial;
}