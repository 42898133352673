$color_white: #fff;
$color_2: #e74c3c;
$color_3: #58bae4;
$color_4: #ffc809;
$color_5: #40A0C9;
$color_6: #f00;
$color_7: #435571;
$color_8: #444;
$color_9: #d17e3a;

.form-style {
  padding: 20px
}

.Addmoreicon {
  display: flex;
  align-items: center;
  justify-content: end;
  margin-top: 8px;
  margin-bottom: 10px;
}

.Addmoreicon p {
  display: flex;
  justify-content: center;
  align-items: center;
}

.addIcon {
  background-color: $color_3;
  color: $color_white;
  border: none;
  border-radius: 50%;
  padding-bottom: 3px;
  width: 45px;
  height: 45px;
  font-size: 31px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);


}

.error-text {
  position: absolute;
  bottom: -16px;
  font-size: 11px;
  color: $color_2;
}

.download-btn-container {
  cursor: pointer;
  transition: 0.3s ease-in-out;
  min-width: 130px;
  font-size: 16px;
  font-weight: 500 !important;
  display: flex;
  align-items: center;
  background: $color_4;
  border-radius: 4px;
  justify-content: center;
  color: $color_white;

  button.btn {
    font-weight: 500;
    font-family: inherit;
    color: $color_white;
    width: 100%;
    transition: 0.3s ease-in-out;
  }

  &:hover,
  &:hover button.btn {
    color: $color_white;
    background-color: $color_4;
  }

  &:active button.btn {
    border-color: transparent;
  }
}

.preview-btn-container {
  cursor: pointer;
  transition: 0.3s ease-in-out;
  min-width: 130px;
  font-size: 16px;
  font-weight: 500 !important;
  display: flex;
  align-items: center;
  background: $color_5;
  border-radius: 4px;
  justify-content: center;
  color: $color_white;

  button.btn {
    font-weight: 500;
    font-family: inherit;
    color: $color_white;
    width: 100%;
    transition: 0.3s ease-in-out;
  }

  &:hover,
  &:hover button.btn {
    color: $color_white;
    background-color: $color_5;
  }

  &:active button.btn {
    border-color: transparent;
  }

  svg {
    height: 25px;
    width: 25px;
  }
}

.modal.show .modal-dialog {
  top: 50%;
  transform: translateY(-50%);
}

.file-preview-modal-title {
  background: $color_3;
  padding: 20px;
  display: flex;
}

.file-preview-modal-title h3 {
  color: $color_white;
  font-size: 18px;
  font-weight: 600;
  margin-top: 1px;
}

.modal-lg {
  width: 40%;
}

.file-preview-modal-body,
.file-preview-modal-footer {
  padding: 20px 20px 20px 20px;
}

.files-card {
  border-radius: 2px;
  border: 1px solid $color_7;
  padding: 15px;
  display: flex;
  margin-bottom: 4px;
  justify-content: space-between;
  align-items: center;
}

.files-card p.files-card-name {
  line-break: anywhere;
  line-height: 18px;
  margin-right: 10px;
  color: $color_8;
}

// .file-cancel {
//   padding: 20px;
//   display: flex;
//   justify-content: end;
// }

// .file-cancel button {
//   width: 154px;
//   height: 48px;
//   border-radius: 4px;
//   border: 1px solid $color_6;
//   background: none;
//   color: $color_6;
// }

// .file-cancel button:hover {
//   background: $color_6;
//   color: $color_white;
// }

.react-pdf__Page {
  display: flex;
  justify-content: center;
}

.react-pdf__Page__textContent {
  display: none !important;
}

.react-pdf__Page__annotations {
  display: none;
}

.main {
  height: 500px;
}

.prev-btn {
  background: $color_9;
  color: $color_white;
  border: none;
  font-size: 16px;
  padding: 5px;
}

.eye-icon {
  background-color: $color_3;
}