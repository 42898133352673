$color_white: #fff;
$color_2: #FFC809;
$background-color_1: #fff;
$background-color_2: #FFC809;
$background-color_3: #44A6F0;
$background-color_4: #2f92dd;

.main-lgn-cntnr {
	width: 100%;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}

.lgn-cntnr-lft {
	width: 65%;
	padding: 20px;
	position: relative;
}

.staff-bg-image {
	background: url('../../../image/loginpage.jpg')
}

.vendor-bg-image {
	background: url('../../../image/vendorloginPage.jpg')
}

.finance-bg-image {
	background: url('../../../image/financeloginPage.jpg')
}

.lgn-common {
	min-height: 100vh;
	background: url('../../../image/loginpage.jpg');
	-o-object-fit: contain;
	object-fit: contain;
	background-repeat: no-repeat;
	background-size: cover;
}

.lgn-logo {
	width: 180px;
	height: auto;
}

.lgn-lft-cntn {
	width: 700px;
	text-align: center;
	position: relative;
	position: absolute;
	top: 50%;
	left: 50%;
	color: $color_white;
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);

	h2 {
		font-size: 58px;
		position: relative;
		display: inline-block;
		margin-bottom: 25px;

		&::before {
			height: 2px;
			background-color: $background-color_1;
			content: "";
			width: 200px;
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			position: absolute;
			-webkit-box-align: center;
			-ms-flex-align: center;
			align-items: center;
			bottom: 0;
			left: 50%;
			-webkit-transform: translate(-50%);
			-ms-transform: translate(-50%);
			transform: translate(-50%);
		}
	}

	p {
		font-size: 16px;
		line-height: 22px;
		margin: 0;
	}
}

.lgn-cntnr-rgt {
	width: 35%;
	min-height: 100vh;
	height: 100%;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	background: rgba(67, 67, 67, 0.7);
	padding: 20px;
	-webkit-box-shadow: -4px 0px 44px rgba(0, 0, 0, 0.45);
	box-shadow: -4px 0px 44px rgba(0, 0, 0, 0.45);
	color: $color_white;
}

.lgn-rgt-cntn {
	h3 {
		font-size: 26px;
		font-weight: 500;
		margin-bottom: 6px;
	}

	>p {
		color: $color_white;
		font-size: 14px;
		line-height: 17px;
		font-weight: 300;
		letter-spacing: 0.1px;
		margin-bottom: 25px;
	}
}

.lgn-selection {
	margin-bottom: 20px;

	>label {
		font-size: 14px;
		font-weight: 400;
	}

	.input-radio {
		-webkit-box-shadow: 0px 0px 0px 1px #6d6d6d;
		box-shadow: 0px 0px 0px 1px #6d6d6d;
		width: 16px;
		height: 16px;
		cursor: pointer;
		margin-right: 7px;
		border: 2px solid #fff;
		background-clip: border-box;
		border-radius: 50%;
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
		-webkit-transition: background-color 0.3s, -webkit-box-shadow 0.3s;
		transition: background-color 0.3s, -webkit-box-shadow 0.3s;
		-o-transition: background-color 0.3s, box-shadow 0.3s;
		transition: background-color 0.3s, box-shadow 0.3s;
		transition: background-color 0.3s, box-shadow 0.3s, -webkit-box-shadow 0.3s;

		&:checked {
			-webkit-box-shadow: 0px 0px 0px 4px #FFC809;
			box-shadow: 0px 0px 0px 4px #FFC809;
			background-color: $background-color_2;
		}
	}
}

.lgn-captcha {
	height: 37.6px;
	border-radius: 6px;
	background-color: $background-color_2;
	color: $color_white;
	font-weight: 600;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	font-size: 22px;
	letter-spacing: 20px;
	margin-bottom: 4px;
}

.captcha-container {
	margin-bottom: 35px;
}

.sign-in-btn {
	.btn-signin {
		height: 48px;
		border-radius: 6px;
		background-color: $background-color_3;
		color: $color_white;
		font-weight: 500;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		width: 100%;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-transition: 0.3s ease-in-out;
		-o-transition: 0.3s ease-in-out;
		transition: 0.3s ease-in-out;
		font-size: 18px;

		&:hover {
			background-color: $background-color_4;
		}
	}

	a {
		font-size: 14px;
		color: $color_white;
		margin-top: 5px;
		display: inline-block;
		float: right;
		font-weight: 300;

		&:hover {
			text-decoration: underline;
		}
	}
}

.forgot-pwd-form {
	margin-bottom: 35px;
}

.reset-pwd-form {
	margin-bottom: 35px;
}

.verify-otp-form {
	margin-bottom: 35px;
}

.bck-circle-icn {
	margin-bottom: 20px;
}

.otp-field {
	width: 16.66% !important;
	margin: 0 3px 10px 3px;
	height: 38px;
	border-radius: 6px;

	&:first-child {
		margin-left: 0;
	}

	&:last-child {
		margin-right: 0;
	}
}

.rsnd-otp {
	p {
		font-size: 14px;
		font-weight: 400;
		color: $color_2;
		margin: 0;
	}

	span {
		margin-left: 5px;
		font-size: 16px;
		font-weight: 700;
	}
}

.input-pass-icon {
	width: 49.6px;
	background-color: #f8f9fa !important;
	border: var(--bs-border-width) solid var(--bs-border-color);
	border-radius: 0 0.375rem 0.375rem 0px !important;
	align-items: center;
	justify-content: center;
}