$color_white: #fff;
$color_offwhite: #fcfcfc;
$color_dullwhite: #ddd;
$color_green: #06BCA1;
$color_2: #58BAE4;
$color_3: #e74c3c;
$font-family_1: inherit;
$background-color_1: #ff5050;

.mainDiv, .optionalDiv {
	border-radius: 4px;
	border: 1px solid $color_dullwhite;
	background: $color_offwhite;
	padding: 25px 0px 14px 0px;
	position: relative;
	margin: 0 0 30px;
}
.remove-icon {
	width: 35px;
	height: 35px;
	border-radius: 5px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	background-color: $background-color_1;
	color: $color_white;
	position: absolute;
	font-size: 28px;
	font-weight: 500;
	cursor: pointer;
	top: -17px;
	right: 40px;
}
.add-more-btn {
	cursor: pointer;
	-webkit-transition: 0.3s ease-in-out;
	-o-transition: 0.3s ease-in-out;
	transition: 0.3s ease-in-out;
	min-width: 100px;
	font-size: 0.875rem;
	padding: 5px;
	font-weight: 500;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: $color_2;
	border: 1px solid $color_2;
	border-radius: 4px;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	color: $color_white;
	button.btn {
		font-weight: 500;
		font-family: $font-family_1;
		color: $color_2;
		width: 100%;
		-webkit-transition: 0.3s ease-in-out;
		-o-transition: 0.3s ease-in-out;
		transition: 0.3s ease-in-out;
	}
	&:hover {
		color: $color_white;
		background-color: $color_2;

		button.btn {
			color: $color_white;
			background-color: $color_2;
		}
	}
}
.add-more-btn.btn-sync {
	background-color: $color_green;
	border-color: $color_green;
}
.validate_error-div {
	position: absolute;
	padding-top: 2px;
	font-size: 11px;
	color: $color_3;
}
.description-width {
	width: 320px;
}
