$color_white: #fff;
$color_2: #495057;
$font-family_1: "Arial", sans-serif;
$background-color_1: #58bae4;
$background-color_2: #fff;
$background-color_3: #edf2fc;

.card {
	margin: 100px 20px;
}

.card-title {
	font-size: 18px;
	font-family: $font-family_1;
}

.card-body {
	padding: 20px;
}

.input-new-table {
	width: 300px;
}

.table {
	thead {
		th {
			background: #58bae4 !important;
			color: $color_white !important;
			font-size: 14px;
			font-weight: 600;
			font-family: $font-family_1;
			text-wrap: nowrap;
		}
	}
}

.tbl-body-shadow {
	-webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.table-theme-1 {

	tr {
		th {
			color: $color_white;
			background-color: $background-color_1;
			text-wrap: nowrap;
		}

		-webkit-box-shadow: none;
		box-shadow: none;
		border: 1px solid #efefef;

		&:nth-of-type(even) {
			background-color: $background-color_3;
		}
	}

	th {
		background: #58bae4 !important;
		color: $color_white;
		border: 1px solid #58bae4 !important;
		padding: 1rem 0.5rem;
		z-index: 9;
		font-family: $font-family_1;
		font-size: 14px;
		font-style: normal;
		font-weight: 700;
		line-height: normal;
		background-color: $background-color_2;
		letter-spacing: 0.04em;
	}

	td {
		position: relative;
		border: 0 !important;
		border-left: 0 !important;
		border-right: 0 !important;
		padding: 8px 10px;
		color: $color_2;
		font-family: $font-family_1;
		font-size: 13px;
		font-style: normal;
		font-weight: 400;
		line-height: 20px;
		letter-spacing: 0.04em;
	}
}

#MyTable {
	-webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23) !important;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23) !important;
}

#MyTables {
	-webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23) !important;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23) !important;
}

.table-tfoot {
	td {
		border: none;
	}

	tr {
		td.shadowclass {
			-webkit-box-shadow: 2px 2px 2px 0px #a49e9e;
			box-shadow: 2px 2px 2px 0px #a49e9e;
			background: #ffc809;
			font-size: 14px;
			color: #333!important;
			font-weight: 600;
			text-align: end;
		}
	}
}

.total-show {
	background: #ffc809;

	td{
		font-size: 14px;
		color: #333!important;
	}
}

.table-footer {
	background-color: $background-color_2 !important;
	border: none;
}

.custom-width {
	width: 100px;
}

.custom-new-width {
	width: 130px;
}

.cstm-icon-gap {
	margin-left: 6px;
}

.cstm-eye-icon-gap {
	margin-left: 6px;
}

.cstm-sync {
	display: flex;
	justify-content: center;
	align-items: center;
	color: #fff;
	min-width: 120px;
	padding: 5px 10px;
	font-weight: 600;
	border-radius: 20px;
}

.truncate-error-text {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 150px;
	display: inline-block;
}

.des-width {
	width: 180px;
}

td.description-width {
	width: 200px;
}