$color_white: #fff;
$color_red: red;
$font-family_1: inherit;
$background-color_1: #ffc809;
$background-color_2: transparent;

.data-info-box {
	border-radius: 4px;
	background: #46708f;
	-webkit-box-shadow: 0 8px 11px rgba(0, 0, 0, 0.25), 0 3px 4px rgba(0, 0, 0, 0.22);
	box-shadow: 0 8px 11px rgba(0, 0, 0, 0.25), 0 3px 4px rgba(0, 0, 0, 0.22);
	color: $color_white;
	width: 100%;
	height: auto;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	padding-top: 10px;

	.imgpinicon {
		width: 57.261px;
		height: 53.183px;
		-webkit-transform: rotate(14.787deg);
		-ms-transform: rotate(14.787deg);
		transform: rotate(14.787deg);
	}
	.data-info {
		width: 100%;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		width: 100%;
	}

	.data-group {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		width: 100%;
		padding: 10px;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		flex-wrap: wrap;

		.data-item {
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			margin-bottom: 1rem;
			-webkit-box-align: center;
			-ms-flex-align: center;
			align-items: center;
			width: 50%;
			padding: 0 10px;
		}

		.label {
			width: 30%;
			margin-right: 1rem;
			font-size: 14px;
		}

		.semicolon {
			margin-right: 0.5rem;
			width: 20%;
			font-size: 14px;
		}

		.value {
			width: 50%;
			font-size: 14px;
			word-wrap: break-word;
		}
	}
}





.cstm-prvbtn-box {
	justify-content: space-between;
	flex: 1;
}

.preview-btn {
	cursor: pointer;
	-webkit-transition: 0.3s ease-in-out;
	-o-transition: 0.3s ease-in-out;
	transition: 0.3s ease-in-out;
	min-width: 110px;
	font-size: 16px;
	font-weight: 500 !important;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	background: #ffc809;
	border-radius: 4px;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	color: $color_white;
	margin: 5px 0 5px 0;

	button.btn {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		justify-content: center;
		font-weight: 500;
		font-family: $font-family_1;
		color: $color_white;
		width: 100%;
		-webkit-transition: 0.3s ease-in-out;
		-o-transition: 0.3s ease-in-out;
		transition: 0.3s ease-in-out;
		padding: 4px;

		svg {
			width: 20px;
		}

		span {
			font-size: 14px;
		}
	}

	&:hover {
		color: $color_white;
		background-color: $background-color_1;

		button.btn {
			color: $color_white;
			background-color: $background-color_1;
		}
	}
}

.create-btn {
	background: #008117;
	border-radius: 4px;
	color: $color_white;
	min-width: 110px;
	font-size: 16px;
	font-weight: 500 !important;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	cursor: pointer;
	-webkit-transition: 0.3s ease-in-out;
	-o-transition: 0.3s ease-in-out;
	transition: 0.3s ease-in-out;
	border: 1px solid #008117;
	margin: 5px 0 5px 0;

	button.btn {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		justify-content: center;
		font-weight: 500;
		font-family: $font-family_1;
		-webkit-transition: 0.3s ease-in-out;
		-o-transition: 0.3s ease-in-out;
		transition: 0.3s ease-in-out;
		color: $color_white;
		padding: 4px;
		width: 100%;

		svg {
			width: 20px;
		}

		span {
			font-size: 14px;
		}
	}

	&:hover {
		background: #008117;
		border: 1px solid #008117;
	}
}

.required {
	color: $color_red;
	margin-left: 3px;
}

.back-viewbtn {
	border: none;
	background-color: $background-color_2;
}

.mb-20 {
	margin-bottom: 20px;
}

.statusicon {
	border-radius: 100px;
	width: 10px;
	height: 10px;
}

.upload-documents-fields {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}

.file-view-button {
	position: relative;
}

.mdi-close-circle {
	position: absolute;
	top: -10px;
	left: 109px;
	bottom: 48px;
	color: $color_red;
	cursor: pointer;
	margin-left: 5px;
	z-index: 1;
}

.view-file-cross-icon {
	background: #005c82;
	border-radius: 4px;
	color: $color_white;
	font-size: 16px;
	border-top-right-radius: 4px;
	border-bottom-right-radius: 4px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	cursor: pointer;
	min-width: 120px;
	-webkit-transition: 0.3s ease-in-out;
	-o-transition: 0.3s ease-in-out;
	transition: 0.3s ease-in-out;
	border: 1px solid #005c82;

	button.btn {
		font-weight: 500;
		font-family: $font-family_1;
		-webkit-transition: 0.3s ease-in-out;
		-o-transition: 0.3s ease-in-out;
		transition: 0.3s ease-in-out;
		color: $color_white;
		width: 100%;
	}

	&:hover {
		background: #005c82;
		border: 1px solid #005c82;
	}
}

.updated-value {
	display: flex;
	align-items: center;
	color: #ef8012;
	width: 50%;
	font-size: 14px;
	word-wrap: break-word;
}

.updated-bankInfo-value {
	color: #ef8012;
}