@media screen and (max-width: 1400px) {
    .cstm-vendor-details .form-style .wrapper {
        flex-wrap: wrap;
    }

    .wrapper .contact-profile-Card {
        width: 49.2%;
    }
}

@media screen and (max-width: 1200px) {
    .lgn-cntnr-lft {
        width: 55%;
    }

    .lgn-cntnr-rgt {
        width: 45%;
        min-height: 100vh;
        height: 100%;
    }

    .lgn-lft-cntn {
        width: 500px;
    }

    .lgn-lft-cntn h2 {
        font-size: 38px;
        margin-bottom: 20px;
    }

    .lgn-lft-cntn p {
        font-size: 14px;
        line-height: 20px;
    }

    .data-inv-group-1 {
        width: 47%;
    }

    .data-inv-group-2 {
        width: 52%;
    }

    .data-details-3 {
        width: 40%;
    }

    .modal-lg {
        width: 50%;
    }

    .GST-bill-width {
        width: 65%;
    }

    .makeStyles-paper-2,
    .jss2 {
        width: 60% !important;
    }

    .approval-flow-structure .jss2 {
        width: 100% !important;
    }

    .data-info-box {
        flex-direction: column;
    }

    .file-preview-modal-body,
    .file-preview-modal-footer {
        padding: 20px 10px 20px 10px;
    }

    .report-inpfiled-container {
        flex-wrap: wrap;
    }

    .report-inpfiled-container .field-flex {
        flex-basis: 200px;
    }

}

@media screen and (max-width: 992px) {
    .page-inner-container {
        margin: 80px 10px 20px 10px;
    }

    .form-style {
        padding: 20px 10px 20px;
    }

    .cstm-stepper {
        padding: 0 10px 20px;
    }

    .finance-view-accordian {
        padding: 20px 10px;
    }

    .section-header {
        padding: 0 10px;
    }

    .bank-accordian-box .accordion .accordion-item h2.accordion-header button.accordion-button {
        padding: 0 10px;
    }

    .layout .sidebar-container {
        left: -240px;
    }

    .layout .page-container {
        width: calc(100% - 0px);
        margin: 0 0 0 0px;
    }

    .layout .header {
        width: 100%;
    }

    .layout.sidebar-toggle-active .sidebar-container {
        left: 0;
    }

    .layout.sidebar-toggle-active .page-container {
        width: 100%;
        margin: 0 0 0 0;
    }

    .layout.sidebar-toggle-active .header {
        width: 100%;
    }

    .lgn-lft-cntn {
        width: 400px;
    }

    .lgn-lft-cntn h2 {
        font-size: 38px;
        margin-bottom: 20px;
    }

    .lgn-lft-cntn p {
        font-size: 14px;
        line-height: 20px;
    }

    .modal-lg {
        width: 70%;
    }

    .makeStyles-paper-2,
    .jss2 {
        width: 70% !important;
    }

    .approval-flow-structure .jss2 {
        width: 100% !important;
    }

    .layout .header-logo {
        img {
            display: block;
        }
    }

    .sidebar-toggle-active .page-container .navbar.header .header-logo img {
        display: none;
    }

    .data-info-box .data-group .data-item {
        width: 100%;
    }

    #overlayer .spin {
        left: 43%;
    }
}

@media screen and (max-width: 768px) {

    .custom-width select,
    .custom-width input {
        width: 150px;
    }

    .lgn-cntnr-lft {
        display: none;
    }

    .lgn-cntnr-rgt {
        width: 100%;
    }

    .lgn-rgt-cntn-logo {
        display: block;
    }

    .data-inv-info {
        flex-direction: column;
    }

    .data-inv-group-1 {
        width: 100%;
        padding: 0;
        margin-bottom: 20px;
    }

    .data-inv-group-2 {
        width: 100%;
        padding: 0;
        margin-bottom: 20px;
    }

    .data-details-2 .data-info-label {
        margin-right: 0;
        width: 35%;
        line-height: 18px;
    }

    .data-details-2 .data-info-semicolon {
        margin-right: 0;
        width: 10%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .data-details-2 .data-info-value {
        width: 55%;
        text-align: right;
        font-size: 14px;
        word-wrap: break-word;
    }

    .data-details-3 {
        width: 60%;
    }

    .data-info-box .imgpinicon {
        width: 20px;
        height: 50px;
    }

    .data-info-box .data-info {
        flex-direction: column;
    }

    .data-info-box .data-group {
        padding: 0 10px;
    }

    .data-info-box .data-group .label {
        width: 30%;
        margin-right: 0;
    }

    .data-info-box .data-group .semicolon {
        margin-right: 0;
        width: 10%;
        display: flex;
        justify-content: center;
    }

    .data-info-box .data-group .value {
        width: 60%;
        font-size: 14px;
        word-wrap: break-word;
    }

    .wrapper .contact-profile-Card {
        width: 100%;
    }

    .cstm-vendor-details .form-style .wrapper {
        flex-direction: column;
    }

    .contact-profile-Card {
        padding: 30px 10px 10px;
    }

    .GST-bill-width {
        width: 75%;
    }

    .makeStyles-paper-2,
    .jss2 {
        width: 85% !important;
    }

    .approval-flow-structure .jss2 {
        width: 100% !important;
    }

    .rotate-arrow>span {
        transform: rotate(90deg);
    }

    #overlayer .spin {
        left: 41%;
    }

    .cstm-stepper .container-section .stepper-bill-slct {
        margin: 30px 20px;
        width: initial;
    }
}

@media screen and (max-width: 668px) {
    .change_password_form .change_pasword_card {
        position: relative;
        top: inherit;
        left: inherit;
        margin: 20px 10px 20px;
        transform: inherit;
    }

    .change_password_card_title {
        width: inherit;
    }

    .change_password_form {
        padding-bottom: 30px;
        min-height: initial;
    }

    .GST-bill-width {
        width: 85%;
    }

    .makeStyles-paper-2,
    .jss2 {
        width: 95% !important;
    }

    .approval-flow-structure .jss2 {
        width: 100% !important;
    }

    .cstm-stepper .cstm-stepper-scroller>div>div {
        width: 130% !important;
    }

    .report-tab-section .tab {
        min-width: 100px;
        padding: 12px 10px !important;
        font-size: 14px;
        text-wrap: nowrap;
    }

    .thank-you-box .thankyou {
        width: 100%;
        padding: 0 10px;
    }

}

@media screen and (max-width: 576px) {
    .page-inner-container {
        margin: 80px 10px 20px 10px;
    }

    .create-btn {
        min-width: 120px;
        margin: 5px 0 5px 0;
    }

    .preview-btn {
        min-width: 100px;
        margin: 5px 0 5px 0;
    }

    .cstm-edit-btn {
        min-width: 100px;
        margin: 5px 0 5px 0;
    }

    .preview-btn button.btn,
    .create-btn button.btn,
    .cstm-edit-btn button.btn {
        padding: 4px;
        height: 34px;
    }

    .preview-btn button.btn svg,
    .create-btn button.btn svg,
    .cstm-edit-btn button.btn svg {
        width: 16px;
        height: auto;
    }

    .preview-btn button.btn span,
    .create-btn button.btn span,
    .cstm-edit-btn button.btn span {
        font-size: 14px;
    }

    .section-header h3 {
        font-size: 16px;
    }

    .section-sub-heading h3,
    .bank-accordian-box .accordion .accordion-item h2.accordion-header button.accordion-button h3 {
        font-size: 16px;
    }

    .section-header .back-viewbtn>div {
        margin-right: 0px !important;
    }

    .section-header .back-viewbtn>div svg {
        width: 16px;
    }

    .data-info-box .imgpinicon {
        width: 20px;
        height: 32px;
    }

    .data-info-box .imgpinicon img {
        width: 34px;
        height: auto;
    }

    .modal-lg {
        width: 96%;
    }

    .change_password_card_title {
        padding: 14px 10px;
    }

    .change_password_fild {
        padding: 20px 10px;
    }

    .GST-bill-width {
        width: 100%;
    }

    .cstm-stepper .cstm-stepper-scroller>div>div {
        width: 145% !important;
    }

    .fin-info-upl-field {
        flex-wrap: wrap;
        justify-content: end;
    }

    .fin-info-upl-field .file-view-button {
        padding: 10px 0 0 0 !important;
    }

    .layout .navbar-expand-sm .navbar-nav .dropdown-menu {
        transform: initial !important;
        inset: inherit !important;
        right: -46px !important;
    }

    .section-inv-header h3.bill-header-title {
        background-color: #fff;
        font-size: 16px;
        width: 150px;
        margin: 0 9vw 0 auto;
    }

    #overlayer .spin {
        left: 38%;
    }

    .cstm-stepper .container-section .stepper-bill-slct .radio-bill-selection {
        width: initial;
    }

    .cstm-stepper .container-section .stepper-bill-slct .radio-bill-selection .form-check label {
        font-size: 18px;
    }

    .cstm-stepper-bill-btn {
        min-width: 126px;
        height: 38px;

        button.btn {
            min-height: 38px;
        }
    }

    .inv-gst-tbl-cont .gst-cont-semicolumn {

        margin: 0 5px 0 0;
    }

}

@media screen and (max-width: 480px) {
    .data-details-3 {
        width: 100%;
    }

    .data-info-box .data-group .label,
    .data-info-box .data-group .semicolon,
    .data-info-box .data-group .value {
        font-size: 13px;
    }

    .input-new-table {
        width: 100%;
    }

    .inv-gst-tbl-cont .gst-cont-label {
        width: 37%;
        font-size: 13px;
    }

    .inv-gst-tbl-cont .gst-cont-input {
        width: 58%;
    }

    .custom-cnfm-mdl.cstm-rjct-bill .modal-footer .danger-outline-btn {
        min-width: 126px;

        button.btn {
            font-size: 14px;
            min-height: 37.6px;
        }
    }

    .custom-cnfm-mdl.cstm-rjct-bill .modal-footer .cstm-lvl-btn {
        min-width: 126px;

        button.btn {
            font-size: 14px;
            min-height: 37.6px;
        }
    }

    .cstm-stepper .cstm-stepper-scroller>div>div {
        width: 220% !important;
    }

    .report-tab-section {
        overflow: auto;
    }

    .report-tab-section .tab {
        min-width: 140px;
        padding: 12px 10px !important;
        font-size: 14px;
        text-wrap: nowrap;
    }

    .optional-div-information .primary-contact,
    .primary-contact {
        left: 9px;
    }

    .optional-div-information .remove-icon {
        right: 10px;
    }

    .preview-btn {
        min-width: 90px;
        margin: 5px 0 5px 0;
    }

    .cstm-edit-btn {
        min-width: 70px;
        margin: 5px 0 5px 0;
    }

    .vendor-logo img {
        width: 105px;
    }

    .report-inpfiled-container {
        flex-direction: column;
    }

    .report-inpfiled-container .field-flex {
        flex-basis: auto;
    }

    .section-inv-header h3.bill-header-title {
        background-color: initial;
        color: #fff;
        font-size: 14px;
        width: initial;
        margin: 0 auto 0 0;
    }


    #overlayer .spin {
        left: 35%;
    }

    .cstm-stepper .container-section .stepper-bill-slct {
        margin: 15px 10px;
        padding: 0 10px;
    }

    .cstm-stepper .container-section .stepper-bill-slct .bill-slct-header {
        margin-top: 25px !important;
    }

    .cstm-stepper .container-section .stepper-bill-slct .bill-slct-header img {
        width: 60px;
    }

    .cstm-stepper .container-section .stepper-bill-slct p {
        padding-top: 10px;
        font-size: 14px;
    }

}

@media screen and (max-width: 380px) {

    .section-inv-header {
        flex-wrap: wrap;
        height: initial;
        padding: 5px 10px;

        h3.bill-header-title {
            font-size: 16px;
        }

        .view-bill-container {
            width: 100%;
            justify-content: end;
        }
    }

    #overlayer .spin {
        left: 32%;
    }

    .cstm-stepper .container-section .stepper-bill-slct .radio-bill-selection .form-check label {
        font-size: 16px;
    }

}