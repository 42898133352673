$color_white: #fff;
$color_2: #444;
$color_3: #666;
$background-color_1: #58BAE4;

#custom-modal {
	border-radius: 4px;
	outline: none !important;
}

.cstm-frm-mdl {
	border-radius: 4px;

	.modal-header {
		background-color: $background-color_1;
		color: $color_white;
		padding: 8px 10px;
		border-radius: 4px 4px 0 0;

		h4 {
			font-size: 22px !important;
			color: $color_white;
			font-weight: 500 !important;
			margin: 0;
		}
	}

	.modal-body {
		padding: 12px 10px 15px;
		font-size: 16px;
		min-height: 100px;
	}

	.modal-footer {
		border-top: 1px solid #c7c7c7;
		padding: 12px 10px 20px;
		margin-top: 20px;
		border-radius: 0 0 4px 4px;
	}
}

.modal-header-container {
	background-color: $background-color_1;
	color: $color_white;
	padding: 8px 10px;
	border-radius: 4px 4px 0 0;

	h4 {
		font-size: 22px !important;
		color: $color_white;
		font-weight: 500 !important;
		margin: 0;
	}
}

.modal-body-container {
	padding: 12px 10px 15px;
	font-size: 16px;
	min-height: 100px;
}

.modal-footer-container {
	border-top: 1px solid #c7c7c7;
	padding: 12px 10px 20px;
	margin-top: 20px;
	border-radius: 0 0 4px 4px;
}

.makeStyles-modal-1 {
	z-index: 9999 !important;
}

.makeStyles-paper-2,
.jss2 {
	width: 40% !important;

	.custom-cnfm-mdl .modal-body {
		padding: 25px 10px 15px !important;
		border-radius: 4px !important;

	}
}

.approval-flow-structure .scrollbar-container {
	margin: 0 auto !important;
}

.approval-flow-structure .jss2 {
	width: 100% !important;
}

.custom-cnfm-mdl {
	.modal-body {
		padding: 25px 30px 15px;
		min-height: 100px;
		text-align: center;

		h2 {
			font-size: 24px;
			font-weight: 500;
			color: $color_2;
			margin-bottom: 20px;
		}

		h6 {
			font-size: 16px;
			color: $color_3;
			font-weight: 400;
			margin-bottom: 0px;
		}

		&.fully-paid-div {
			padding: 100px 50px 100px;
		}
	}

	.modal-footer {
		text-align: center;
		padding: 12px 10px 20px;
		margin-top: 10px;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		justify-content: center;
	}
}

.cross {
	position: absolute;
	right: -4.8px;
	top: -14px;
	cursor: pointer;
}

.view-logo {
	width: 100%;
	height: 350px;
}

.view-logo img {
	border-radius: 5px;
	width: 100%;
	height: 350px;
	object-fit: contain;
}

.custom-cnfm-mdl {
	position: relative;

	.cross {
		position: absolute;
		z-index: 99;
		cursor: pointer;
		right: -5px;
		top: -10px;
	}
}