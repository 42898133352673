$color_white: #fff;

.layout {
	min-height: 100vh;

	.page-container {
		width: calc(100% - 240px);
		overflow-y: auto;
		overflow-x: hidden;
		background: #f2edf3;
		-webkit-transition: 0.3s ease-in-out;
		-o-transition: 0.3s ease-in-out;
		transition: 0.3s ease-in-out;
		margin: 0 0 0 240px;
		position: relative;
	}

	.header {
		background: -webkit-gradient(linear, left top, right top, from(#202639), color-stop(16%, #202639), to(#444791)) !important;
		background: -o-linear-gradient(left, #202639 0%, #202639 16%, #444791 100%) !important;
		background: linear-gradient(90deg, #202639 0%, #202639 16%, #444791 100%) !important;
		height: 56px;
		padding: 5px 20px;
		width: calc(100% - 240px);
		position: fixed;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		z-index: 999 !important;
		-webkit-transition: 0.3s ease-in-out;
		-o-transition: 0.3s ease-in-out;
		transition: 0.3s ease-in-out;
	}

	.header-logo {
		img {
			width: 130px;
			height: auto;
			display: none;

		}
	}

	.navbar-expand-sm .navbar-nav .dropdown-menu {
		z-index: 9999;
		box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.35);
		border: 0;
		width: 260px;
	}

	.navbar-expand-sm .sidebar-humburger {
		padding: 5px;
		font-size: 1.5rem;
		color: #fff;
		background-color: transparent;
	}

	.profile {
		ul {
			li.user-name {
				display: -webkit-box;
				display: -ms-flexbox;
				display: flex;
				-webkit-box-align: center;
				-ms-flex-align: center;
				align-items: center;

				p {
					color: $color_white;
					font-size: 16px;
					margin: 0;
					line-height: 0;
				}
			}
		}
	}


}

