$color_lgt-black: #222;
$color_white: #fff;
$color_3: #00c1a4;
$color_orange: orange;
$font-family_1: Arial, Helvetica, sans-serif;
$background-color_1: #58bae4;
$background-color_2: white;
$border-color_1: #58bae4;

.edit-vendor-container {
	border: 1px solid #909090;
}
.cstm-statement-div {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
}

.cstm_instruction {
	height: 400px;
}

.cstm_instruction_div {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	text-align: left;
}

.cstm_instruction_div_1 {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	text-align: left;
	margin-bottom: 20px;
}

.thumbs_svg {
	svg {
		width: 23px;
		height: 27px;
	}
}

.cstm_paragraph_inst_div {
	margin-left: 15px;
}

.under_statement_div {
	color: $color_lgt-black;
	font-family: $font-family_1;
	font-size: 18px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	margin-right: 10px;
}

.stepper-next-btn {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	margin-top: 20px;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}

.file-input-container {
	input[type="file"] {
		&::-webkit-file-upload-button {
			border: 1px solid #58bae4;
			color: $color_white;
			background-color: $background-color_1;
			-webkit-transition: 1s;
			transition: 1s;
		}

		&::file-selector-button {
			border: 1px solid #58bae4;
			color: $color_white;
			background-color: $background-color_1;
			-webkit-transition: 1s;
			-o-transition: 1s;
			transition: 1s;
		}

		&::-webkit-file-upload-button:hover {
			border: 1px solid #58bae4;
			color: $color_white;
			background-color: $background-color_1;
			-webkit-transition: 1s;
			-o-transition: 1s;
			transition: 1s;
		}
	}
}

.thank-you-box {
	background-color: $background-color_2;
	margin: auto;
	text-align: center;

	h2 {
		margin-bottom: 10px;
	}
}

.thank-you-icon {
	background: #fafafa;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}

.fa-cstm-icon {
	font-size: 50px;
	color: $color_3;
	font-size: 70px;
	margin-top: 30px;
}

.fa-cstm-icon-back {
	background: #00c1a4;
	color: $color_white;
	border: none;
	font-size: 20px;
	background: #00c1a4;
	border-radius: 5px;
	margin-top: 20px;
	padding: 10px 10px;
}

.gif-sucess {
	width: 100%;
	margin: 10px;
	min-height: 210px;

	img {
		width: 280px;
	}
}

.thankyou {
	background: #fafafa;
	-webkit-box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.15);
	box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.15);
	width: 550px;
	display: inline-block;
	margin-bottom: 20px;
	margin-top: 20px;
	max-height: auto;
	border-top: 10px solid #ffc809;
}

.pending-top-border {
	border-top: 10px solid orange;
}

.faarrowbtn {
	padding-right: 10px;
	font-size: 30px;
}

.pending-bg-btn {
	background: orange;
}

.waitingmsg {
	color: $color_orange;
}

.imagelogo-pending {
	width: 100%;
	margin: 10px;
	min-height: 150px;

	img {
		width: 150px;
	}
}

.cstm-stepper {
	padding: 0 20px 20px;
}

.cstm-stepper .cstm-stepper-scroller {
	overflow: auto;
	padding-bottom: 10px;
	margin-bottom: 20px;
}

.cstm-stepper .cstm-stepper-scroller>div>div>div {
	width: 45% !important;
	display: flex !important;
	flex-direction: column;
	white-space: nowrap;
}

.cstm-stepper .cstm-stepper-scroller>div>div>div>div {
	display: flex !important;
	align-items: center;
	justify-content: center;
}

.cstm-stepper .cstm-stepper-scroller>div>div {
	display: flex !important;
}

.imagelogo img {
	width: calc(260px - 120px);
	max-width: 100%;
	height: 28px;
	margin-top: 10px;
	margin-left: 10px;
	vertical-align: middle;
}

.cstm-stepper .container-section {
	margin-left: 0px;
	margin-right: 0px;
	border: 1px solid #909090;
}