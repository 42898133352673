@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,900;1,300&display=swap");
@import "./partials/pages/authentication.scss";
@import "./partials/pages/maincontainer.scss";
@import "./partials/components/loader.scss";
@import "./partials/components/table.scss";
@import "./partials/components/purchaseContract.scss";
@import "./partials/components/viewContract.scss";
@import "./partials/modals/approve_contract.scss";
@import "./partials/components/vendor.scss";
@import "./partials/components/Bill.scss";
@import './partials/components/addItem.scss';
@import './partials/components/stepperForm.scss';
@import './partials/components/dashboard.scss';

$color_white: #fff;
$color_offwhite: #fcfcfc;
$color_1: #ffb171;
$color_2: #343a40;
$color_3: #ff5a5a;
$color_4: #fca510;
$color_5: #58bae4;
$color_6: #06BCA1;
$color_7: #03b096;
$color_8: #ddd;
$color_9: #888;

$color_10: #e4e4e4;
$color_11: #eae8ea;
$color_12: #fafafa;

$color_red: #ff0000;
$color_orange: #ec9600;
$color_black: #000;
$color_ligt_black: #333;
$font-family_1: "Roboto", sans-serif;
$font-family_2: inherit;

$background-color_3: #f4f4f4;
$background-color_red: #f00;
$background-color_6: transparent;
$background-color_8: #339dca;
$background-color_9: #ff5050;
$background-color_10: #f8f9fa;
$background-color_11: #007bff;
$background-color_12: #ffca58;
$background-color_14: #FF4D4D;
$border-color_1: #dee2e6;
$border-color_2: #0040a1 transparent #0040a1 transparent;

.active>.page-link,
.page-link.active {
    z-index: 3;
    color: $color_white;
    background-color: $color_5;
    border-color: $color_5
}

.min-width-inputfield {
    min-width: 160px;
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

/* spinner css end */

* {
    padding: 0;
    margin: 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    scroll-behavior: smooth;
    font-family: $font-family_1;
}

a {
    text-decoration: none;
}

ul {
    list-style: none;
    padding: 0;
}

p {
    margin: 0;
}

.cursor-pointer {
    cursor: pointer;
}

body {
    font-size: 14px;
    overflow-x: hidden;
}

ul.pagination {
    overflow: auto;

    .custom-button {
        background-color: #58bae4 !important;
        border-color: #58bae4 !important;
        cursor: pointer;
        height: 37.6px;
        color: rgb(255, 255, 255) !important;
        padding: 8px 12px !important;
    }

    .custom-button.disabled {
        background-color: #ccc !important;
        border-color: #ccc !important;
        cursor: not-allowed !important;
        color: rgb(255, 255, 255) !important;
        padding: 8px 12px !important;
    }

    .custom-button:last-child {
        border-radius: 0 4px 4px 0 !important;
    }

    .custom-button:first-child {
        border-radius: 4px 0 0 4px !important;
    }

}

.break-all {
    word-break: break-all;
}

.page-inner-container {
    position: relative;
    min-height: calc(100vh - 100px);
    border-radius: 4px;
    margin: 80px 20px 20px 20px;
}

.nav-logout {
    i {
        font-size: 1.5rem;
        color: $color_white;
    }
}

.lgn-rgt-cntn-logo {
    margin-bottom: 30px;
    display: none;
}

.dropdown-btn {
    padding: 5px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background: none;
    border: none;
}

.dropdown-toggle {
    &::after {
        display: none;
    }
}

.w-40 {
    width: 40%;
}

.container-section {
    background-color: $color_white;
    -webkit-box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
}

.form-style {
    padding: 20px;
}

.section-header {
    background-color: $background-color_3;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0 20px;
    border-radius: 4px 4px 0 0;

    .cstm-bill-recon-sec {
        display: flex;
        gap: 20px;

        .recon-StatusIcon1 {
            display: inline-block;
            border-radius: 100px;
            background-color: $background-color_12;
            margin-right: 7px;
            width: 12px;
            height: 12px;
            animation: changeBackgroundColor1 1s ease-in-out infinite;
        }

        .recon-StatusIcon2 {
            display: inline-block;
            border-radius: 100px;
            background-color: $color_6;
            margin-right: 7px;
            width: 12px;
            height: 12px;
            animation: changeBackgroundColor2 1s ease-in-out infinite;
        }

        .recon-StatusIcon3 {
            display: inline-block;
            border-radius: 100px;
            background-color: $background-color_14;
            margin-right: 7px;
            width: 12px;
            height: 12px;
            animation: changeBackgroundColor3 1s ease-in-out infinite;

        }
    }

    @keyframes changeBackgroundColor1 {
        0% {
            background-color: $background-color_6;
        }

        100% {
            background-color: $background-color_12;
        }
    }

    @keyframes changeBackgroundColor2 {
        0% {
            background-color: $background-color_6;
        }

        100% {
            background-color: $color_6;
        }
    }

    @keyframes changeBackgroundColor3 {
        0% {
            background-color: $background-color_6;
        }

        100% {
            background-color: $background-color_14;
        }
    }

    .back-viewbtn {
        padding: 10px 0;
        margin-right: 5px !important;
    }

    h3 {
        color: $color_2;
        font-weight: 500;
        font-size: 18px;
        margin: 0;
        padding: 10px 0;
    }

    .section-hdr-btn {
        background-color: $color_5;
        color: $color_white;
        font-weight: 500;

        &:focus {
            background-color: $color_5;
            color: $color_white;
            font-weight: 500;
        }

        &:active {
            background-color: $color_5;
            color: $color_white;
            font-weight: 500;
        }

        &:hover {
            background-color: $background-color_8;
            color: $color_white;
            font-weight: 500;
        }
    }
}

.section-sub-heading {
    margin-bottom: 10px;

    h3 {
        margin-bottom: 0px;
        color: $color_2;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }
}

.edit-section-header .section-header {
    background-color: $color_5;

    h3 {
        color: $color_white;
    }
}

.add-btn {
    background: $color_6 !important;
    border-radius: 4px;
    color: $color_white !important;
    font-size: 16px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    cursor: pointer;
    min-width: 70px;
    -webkit-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
    border: 1px solid $color_6;
    height: 36.6px;
    line-height: 0;
    padding: 0 10px;

    &:hover {
        background: $color_7 !important;
        border: 1px solid $color_7;
        color: $color_white;
    }
}

.cancel-btn {
    -webkit-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
    min-width: 50px;
    font-size: 16px;
    font-weight: 500 !important;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border: 1px solid $color_3;
    border-radius: 4px;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    color: $color_3 !important;

    &:hover {
        color: $color_white !important;
        background-color: $background-color_red;
    }
}

.danger-outline-btn {
    cursor: pointer;
    -webkit-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
    min-width: 120px;
    font-size: 16px;
    font-weight: 500 !important;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border: 1px solid $color_3;
    border-radius: 4px;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    color: $color_3;

    button.btn {
        font-weight: 500;
        font-family: $font-family_2;
        color: $color_3;
        width: 100%;
        -webkit-transition: 0.3s ease-in-out;
        -o-transition: 0.3s ease-in-out;
        transition: 0.3s ease-in-out;
    }

    &:hover {
        color: $color_white;
        background-color: $background-color_red;

        button.btn {
            color: $color_white;
            background-color: $background-color_red;
        }
    }
}

.danger-btn {
    background: $color_3;
    border-radius: 4px;
    color: $color_white;
    font-size: 16px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    cursor: pointer;
    min-width: 120px;
    -webkit-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
    border: 1px solid $color_3;

    button {
        &.btn {
            font-weight: 500;
            font-family: inherit;
            -webkit-transition: 0.3s ease-in-out;
            -o-transition: 0.3s ease-in-out;
            transition: 0.3s ease-in-out;
            color: $color_white;
            width: 100%;
        }
    }

    &:hover {
        background: $background-color_red;
        border: 1px solid $color_3;
    }

    &.void-bill-button {
        min-width: 100px;

        button {
            &.btn {
                padding: 2px 5px;
            }
        }
    }
}

.cstm-lvl-btn {
    &:hover {
        button.btn {
            color: $color_white;
        }

        background: $color_orange;
        border: 1px solid $color_orange;
        color: $color_white;
    }

    border-radius: 4px;
    font-size: 16px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    cursor: pointer;
    min-width: 167px;
    -webkit-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
    border: 1px solid $color_4;

    button.btn {
        font-weight: 500;
        font-family: $font-family_2;
        -webkit-transition: 0.3s ease-in-out;
        -o-transition: 0.3s ease-in-out;
        transition: 0.3s ease-in-out;
        color: $color_4;
        width: 100%;
    }
}

.success-btn {
    background: $color_6;
    border-radius: 4px;
    color: $color_white;
    font-size: 16px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    cursor: pointer;
    min-width: 120px;
    -webkit-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
    border: 1px solid $color_6;

    button.btn {
        font-weight: 500;
        font-family: $font-family_2;
        -webkit-transition: 0.3s ease-in-out;
        -o-transition: 0.3s ease-in-out;
        transition: 0.3s ease-in-out;
        color: $color_white;
        width: 100%;
    }

    &:hover {
        background: $color_7;
        border: 1px solid $color_7;
    }

    &.release-bill-button {
        min-width: 100px;

        button {
            &.btn {
                padding: 2px 5px;
            }
        }
    }
}

.hold-btn {
    background: $color_4;
    border-radius: 4px;
    color: $color_white;
    font-size: 16px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    cursor: pointer;
    min-width: 120px;
    -webkit-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
    border: 1px solid $color_4;

    button.btn {
        font-weight: 500;
        font-family: $font-family_2;
        -webkit-transition: 0.3s ease-in-out;
        -o-transition: 0.3s ease-in-out;
        transition: 0.3s ease-in-out;
        color: $color_white;
        width: 100%;
    }

    &:hover {
        background: $background-color_12;
        border: 1px solid $background-color_12;
    }

    &.hold-bill-button {
        min-width: 100px;

        button {
            &.btn {
                padding: 2px 5px;
            }
        }
    }
}

.cstm-view-bankinf0-btn {
    background: $color_5;
    border-radius: 4px;
    color: $color_white;
    font-size: 16px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    cursor: pointer;
    min-width: 120px;
    -webkit-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
    border: 1px solid $color_5;

    button.btn {
        font-weight: 500;
        font-family: $font-family_2;
        -webkit-transition: 0.3s ease-in-out;
        -o-transition: 0.3s ease-in-out;
        transition: 0.3s ease-in-out;
        color: $color_white;
        width: 100%;
    }

    &:hover {
        background: $color_5;
        border: 1px solid $color_5;
    }
}





.cstm-stepper-bill-btn {
    border-radius: 4px;
    font-size: 18px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 48px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    cursor: pointer;
    width: 170px;
    -webkit-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
    border: 1px solid #FFC809;
    background-color: #FFC809;


    button.btn {
        font-weight: 500;
        font-family: $font-family_2;
        -webkit-transition: 0.3s ease-in-out;
        -o-transition: 0.3s ease-in-out;
        transition: 0.3s ease-in-out;
        color: #fff;
        font-size: 18px;
    }

    button.btn:active {
        color: #fff !important;
    }
}



.required {
    color: $color_red;
    margin-left: 3px;
}

.mb-20 {
    margin-bottom: 20px;
}

.back-viewbtn {
    border: none;
    background-color: $background-color_6;
}

.primary-card-information {
    border-radius: 4px;
    border: 1px solid $color_5;
    background: $color_offwhite;
    padding: 25px 0 14px 0;
    position: relative;
    margin: 0;
    margin-bottom: 30px;
    margin-top: 20px;
}

.primary-contact {
    position: absolute;
    top: -15px;
    width: 173px;
    padding: 0;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 26px;
    font-size: 16px;
    font-weight: 500;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    left: 39px;
    background: $color_5;
    z-index: 99;
    color: $color_white;
}

.optional-div-information {
    position: relative;
    border-radius: 4px;
    border: 1px solid $color_8;
    background: $color_offwhite;
    padding: 25px 0px 14px 0px;
    margin: 0;
    margin-top: 20px;
    margin-bottom: 30px;

    .primary-contact {
        position: absolute;
        top: -15px;
        width: 173px;
        padding: 0;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        height: 26px;
        font-size: 16px;
        font-weight: 500;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        left: 39px;
        background: $color_white;
        z-index: 99;
        color: $color_ligt_black;
    }

    .css-1oakzvg-MuiButtonBase-root-MuiCheckbox-root .MuiSvgIcon-root {
        font-size: 28px !important;
        color: $color_ligt_black !important;
    }

    .remove-icon {
        width: 35px;
        height: 35px;
        border-radius: 5px;
        display: -webkit-box;
        display: -ms-flexbox;
        line-height: normal;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        background-color: $background-color_9;
        color: $color_white;
        position: absolute;
        cursor: pointer;
        top: -17px;
        right: 40px;
    }
}

.css-1oakzvg-MuiButtonBase-root-MuiCheckbox-root {
    color: $color_white;
    padding: 0 !important;

    .MuiSvgIcon-root {
        font-size: 28px !important;
        color: $color_white !important;
        padding: 0 !important;
    }
}

.css-1oakzvg-MuiButtonBase-root-MuiCheckbox-root.Mui-checked,
.css-1oakzvg-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate {
    padding: 0 !important;
    color: $color_white !important;
}

.MuiButtonBase-root,
.MuiCheckbox-root,
.MuiCheckbox-colorPrimary,
.MuiCheckbox-sizeMedium,
.PrivateSwitchBase-root {
    padding: 0 !important;
}

.inp-mrg-sec {
    border-radius: 0.375rem 0 0 0.375rem;
    background-color: $background-color_10;
    border-color: $border-color_1;
    outline: none;
    max-width: 58px;
    font-size: 13px;

}

.inp-rgt-radius {
    border-radius: 0 0.375rem 0.375rem 0 !important;
}

.change_password_title {
    border-radius: 4px 4px 0px 0px;
    background: $background-color_3;
    padding: 14px 30px;

    h3 {
        color: $color_white;
        font-size: 18px;
        font-weight: 600;
    }
}

.change_password_form {
    min-height: 600px;
    position: relative;

    .change_pasword_card {
        margin: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        border-radius: 4px;
        background: $color_white;
        -webkit-box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.1);
        box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.1);
    }
}

.change_password_card_title {
    padding: 14px 20px;
    border-radius: 4px 4px 0px 0px;
    background: $color_5;
    width: 618px;

    h3 {
        color: $color_white;
        font-size: 18px;
        font-weight: 600;
    }
}

.change_password_fild {
    padding: 30px 20px;
}

.input_group_addon_div {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 50px;
}

.eye_icon_div {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 50px;
    color: $color_white;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    height: 44px;
}

.reject-modal-text-area {
    min-height: 80px !important;
}

.section-hdr-btn {
    width: 88px;
    height: 32px;
    border: none;
    background-color: $background-color_11;
    color: $color_white;
    font-weight: 600;
    margin-left: 5px;
}

.user-info {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid $color_8;
    margin-bottom: 5px;

    .avatar-img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        margin-right: 10px;
        border: 2px solid $color_9;
    }

    .user-name-email-div {
        font-size: 14px;
        color: $color_black;
        white-space: nowrap;
        -o-text-overflow: ellipsis;
        text-overflow: ellipsis;
        word-wrap: break-word;
        max-width: 30ch;
        font-weight: 600;
        margin-bottom: 0;
    }

    .user-name-div {
        font-size: 12px;
        color: $color_black;
        -o-text-overflow: ellipsis;
        word-wrap: break-word;
        max-width: 196px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

}

.nav-logout.user-name .show.dropdown .dropdown-menu.show a {
    -webkit-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
}

.nav-logout.user-name .show.dropdown .dropdown-menu.show a:hover {
    background-color: $color_5;
    color: $color_white;
}

.loadingSpinnerContainer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: calc(100% - 280px);
    width: 100%;
}

.loadingSpinner {
    width: 64px;
    height: 64px;
    border: 8px solid;
    border-color: $border-color_2;
    border-radius: 50%;
    -webkit-animation: spin 1.2s linear infinite;
    animation: spin 1.2s linear infinite;
    top: 50%;
    right: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
}

.cstm-view-file {
    margin-left: 5px;
    background: $color_5;
    border-radius: 4px;
    color: $color_white;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    min-width: 120px;
    transition: 0.3s ease-in-out;
    border: 1px solid $color_5;
}

.cstm-view-file button.btn {
    font-weight: 500;
    font-family: inherit;
    transition: 0.3s ease-in-out;
    color: $color_white;
    width: 100%;
}

.cstm-view-file:hover {
    background: $color_5;
    border: 1px solid $color_5;
}

tbody.item-description-cstm tr td,
tbody.item-description-inc-cstm td {
    padding-bottom: 20px;
}

tbody.item-description-cstm tr td select,
tbody.item-description-cstm tr td input {
    width: 150px;
}

tbody.item-description-inc-cstm td select.form-select.vendorItems {
    min-width: 240px;
}

.primary-card-information .primary-contact .css-1pg92c4.Mui-checked,
.primary-card-information .primary-contact .css-1pg92c4.MuiCheckbox-indeterminate {
    color: $color_white;
}

.primary-card-information .primary-contact .css-1pg92c4 {
    color: $color_white;
}

/* modal css */
.modal-dialog {
    margin-right: auto;
    margin-left: auto;
}

/* bank accordian css */
.bank-accordian-box {
    padding-bottom: 20px;
}

.bank-accordian-box .accordion .accordion-item h2.accordion-header button.accordion-button {
    padding: 0 20px;
}

.bank-accordian-box .accordion .accordion-item h2.accordion-header button.accordion-button h3 {
    color: $color_2;
    line-height: inherit;
    font-weight: 500;
    font-size: 18px;
    margin: 0;
    padding: 10px 0;
}

.bank-accordian-box .accordion .accordion-item {
    border: 0;
}

.cstm-tooltip {
    z-index: 99;
}

.card-body .cstm-card-body {
    border-left: 10px solid $color_red;

}

//* aaproval flow css start *//

// .MuiGrid-root.Timeline-wrapper-1 {
//     height: inherit !important;
//     margin-bottom: 40px;
//     overflow: auto;
// }
.MuiGrid-root.Timeline-wrapper-1 {
    height: inherit !important;
    margin-bottom: 40px;
    overflow: auto;
    overflow-y: hidden;
}

.MuiGrid-root.jss2 {
    box-shadow: none !important;
}

.MuiGrid-root.jss1 {
    height: initial !important;
    margin-bottom: 40px;
    overflow: auto !important;
    overflow-y: hidden !important;
    justify-content: initial !important;
}

.MuiGrid-root .scrollbar-container .ps__rail-x,
.MuiGrid-root .scrollbar-container .ps__rail-y {
    display: none !important;
}

button.btn {
    border: transparent;
}

table.add-item-table-list thead tr th:nth-child(3) div {
    text-align: end;
}

.item-listing-exterId {
    text-align: end;
}

.css-1u9des2-indicatorSeparator {
    display: none !important;
}

.report-inpfiled-container {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
}

.report-inpfiled-container .field-flex {
    flex: 1;
}

.report-inpfiled-container .field-flex .success-btn {
    width: fit-content;
}

.custom-width select,
.custom-width input {
    min-width: 170px;
}

.cstm-preview-container {
    position: absolute;
    left: 0;
    margin: 0;
    top: 1px;
    z-index: 9;
}

.input-group.file-input-container .mdi-close-circle.file-preview-cross {
    position: absolute;
    top: -8px;
    right: -4px;
    color: $color_red;
    cursor: pointer;
    left: inherit;
    z-index: 6;
}

.hold-release-button {
    display: flex;
    justify-content: end;
}

.danger-btn.hold-bill-button {
    height: 25px !important;
    min-width: 80px !important;
}

.danger-btn.cstm-release-btn {
    background-color: $color_6 !important;
    color: $color_6 !important;
}


.dashboard-chart-section .input-group .input-group-prepend .input-group-text {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.dashboard-chart-section .dashboard-chart-title {
    font-size: 16px;
    color: #000;
    font-weight: 600;
    margin-bottom: 15px;
}



.apexcharts-legend.apexcharts-align-center.apx-legend-position-right {
    margin-top: 20px
}

.cstm-frm-mdl {
    max-height: 97vh;
    overflow-y: auto;
    overflow-x: hidden;
}

.bulk-modal-table {
    max-height: 32vh;
    overflow-y: auto;
    margin-bottom: 20px;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.08), 0 2px 3px rgba(0, 0, 0, 0.08)
}

.bulk-modal-table .table-theme-1 th {
    background: #555 !important;
    border: #555 !important;
    padding: 0.75rem 0.5rem !important;
}

.bulk-view-captcha .lgn-captcha {
    font-weight: 500;
    font-size: 18px;
    letter-spacing: 16px;
    background-color: #0000;
    border: 1px solid #000;
    color: #222;
    margin-bottom: 0px;
}




/*`````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````
 -----------------------------------------        COMMON CSS        -------------------------------------------------------

 `````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````*/




/* sidebar.scss start */

.sidebar-container {
    width: 240px;
    background: -webkit-gradient(linear, left top, left bottom, from(#202639), color-stop(7%, #202639), to(#3F4C77));
    background: -o-linear-gradient(top, #202639 0%, #202639 7%, #3F4C77 100%);
    background: linear-gradient(180deg, #202639 0%, #202639 7%, #3F4C77 100%);
    color: $color_white;
    height: 100vh;
    position: fixed;
    -webkit-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
    left: 0;
    z-index: 9999;
    top: 0;
}

.sidebar-logo {
    height: 60px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    img {
        width: 170px;
        height: auto;
    }
}

.profile-img-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 7px 10px 10px 10px;
    margin: 0 0 15px;

    .profile-image {
        border: 1px #7E7E7E solid;
        padding: 5px;
        border-radius: 50px;
        background: #2F2F2F;

        img {
            width: 46px;
            height: 46px;
            border-radius: 100px;
            -o-object-fit: cover;
            object-fit: cover;
        }
    }

    .login-status {
        color: #CFCFCF;
        font-size: 16px;
        font-weight: 500;
        margin: 5px 0;
    }

    .user-login-role {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        font-size: 12px;
        background-color: #ef8012;
        padding: 5px 10px;
        font-weight: 600;
        border-radius: 20px;
        min-width: 120px;
    }
}

ul.sidebar-menu-listing {
    height: calc(100vh - 210px);
    overflow: auto;
    max-height: calc(100vh - 210px);
    margin-bottom: 0;
}

ul.sidebar-menu-listing::-webkit-scrollbar {
    width: 8px;
    border-radius: 5px;
}

ul.sidebar-menu-listing::-webkit-scrollbar-thumb {
    background: #a2a2a2;
    border-radius: 5px;
    width: 20px;
}

ul.sidebar-menu-listing::-webkit-scrollbar-thumb:hover {
    background: #8e8e8e;
}

.sidebar-menu-listing {
    li {
        a {
            cursor: pointer;
            padding: 10px 32px 10px 20px;
            font-size: 14px;
            color: $color_white;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: justify;
            -ms-flex-pack: justify;
            justify-content: space-between;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            margin: 5px 0;
            -webkit-transition: 0.3s ease-in-out;
            -o-transition: 0.3s ease-in-out;
            transition: 0.3s ease-in-out;
            height: 53.2px;

            &:hover {
                background: #3B424F;
            }
        }

        a.active {
            background: #3B424F;
        }

        ul {
            max-height: 0;
            overflow: hidden;
            -webkit-transition: max-height 0.8s ease;
            -o-transition: max-height 0.8s ease;
            transition: max-height 0.8s ease;
        }

        .submenu {
            max-height: 500px !important;
        }
    }
}

.downArrow {
    width: 12px;
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: -webkit-transform 0.5s ease;
    transition: -webkit-transform 0.5s ease;
    -o-transition: transform 0.5s ease;
    transition: transform 0.5s ease, -webkit-transform 0.5s ease;
}

.upArrow {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
}

/* sidebar.scss end */



/* tab bar scss start */

.tab-bar {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    border-radius: 4px;
    border-bottom: 1px solid $color_10;
}

.tab {
    padding: 10px;
    cursor: pointer;
    background: $color_11;
    border-bottom: 2px solid transparent;
    -webkit-transition: background-color 0.3s;
    -o-transition: background-color 0.3s;
    transition: background-color 0.3s;
    border-radius: 4px 4px 0 0;
    margin-right: 5px;
    min-width: 140px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;

    &:hover {
        background-color: $color_12;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
    }
}

.tab-active {
    color: $color_1;
    background-color: $color_white;
    border-bottom: 2px solid $color_1;
}

.section-header .cstm-bill-recon-sec.cstm-bill-legend .recon-StatusIcon2 {
    background-color: #d3d3d3 !important;
    width: 16px;
    height: 16px;
    border-radius: 100px;
    border: 1px solid #999;
}

/*     tab bar scss end     */

.cstm-stepper .container-section .stepper-bill-slct {
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
    border-top: 8px solid #FFC809;
    width: 630px;
    margin: 50px auto;
    padding: 0 20px;
}

.cstm-stepper .container-section .stepper-bill-slct p {
    color: #747474;
    font-size: 16px;
    padding-top: 30px;
}

.cstm-stepper .container-section .stepper-bill-slct .radio-bill-selection {
    width: 430px;
    justify-content: center;
    margin: auto;
}

.cstm-stepper .container-section .stepper-bill-slct .radio-bill-selection .form-check label {
    font-size: 22px;
    font-weight: 500;
}

input[type="password"]::-ms-reveal,
input[type="password"]::-webkit-credentials-auto-fill-button {
    display: none !important;
}

/* maintenance page css */

.maintenance-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: #FFC809;
    padding: 10px;
    text-align: center;
    justify-content: center;
}

.maintenance_container {
    height: 100px;
    width: 100px;
    margin-bottom: 20px;
}

.maintenance_circle {
    animation: pulse 2s ease-in-out infinite;
    background: #ffe794;
    border-radius: 50%;
    height: 100px;
    position: absolute;
    transform: scale(0);
    width: 100px;
    z-index: 0;

}

.maintenance_icon {
    height: 100px;
    width: 100px;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
}

@keyframes pulse {
    0% {
        opacity: 1;
        transform: scale(0);
    }

    100% {
        opacity: 0;
        transform: scale(1.5);
    }
}

@import './partials/components/responsive.scss';