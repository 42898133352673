#overlayer {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 999999!important;
  background: rgba(86, 79, 79, 0.692);
  -webkit-backdrop-filter: blur(5px) !important;
  backdrop-filter: blur(5px) !important;
  // z-index: 4 !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.spin {
  display: block;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 149px;
  height: 140px;
  border-radius: 50%;
  border: 3px solid transparent;
  animation: spin 1s both infinite;
}