$color_1: #495057;
$color_white: #fff;
$color_black: #000;
$color_4: #222;
$font-family_1: "ARIAL", sans-serif;
$background-color_1: #fff;
$background-color_2: #dedede;
$background-color_3: #0040a1;

.wrapper {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: nowrap;
	flex-wrap: nowrap;
	gap: 10px;
	background-color: $background-color_1;
}

.contact-profile-Card {
	border-radius: 4px;
	border: 1px solid #e3e3e3;
	background: #fff;
	padding: 30px 15px 10px;
	-webkit-filter: drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.25));
	filter: drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.25));
	min-height: 177px;
	width: 33.33%;

	.label-div {
		color: $color_1;
		font-size: 14px;
		font-weight: 600;
		min-width: 110px;
	}

	.semicolon-div {
		color: $color_1;
		font-size: 14px;
		font-weight: 600;
		min-width: 30px;
	}

	.value-div {
		color: $color_1;
		font-size: 14px;
		font-weight: 400;
		margin-left: 5px;
		line-break: anywhere;
	}
}

.active-profile {
	border-radius: 4px;
	border: 2px solid #24a148 !important;
	background: #fff;
	-webkit-box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
	box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
	position: relative;
}

.primary-flag {
	background: #fcfcfc;
	display: inline-block;
	border: 1px solid #24a148;
	position: absolute;
	top: -15px;
	color: $color_white;
	font-size: 14px;
	font-weight: 500;
	min-height: 26px;

	.check {
		background: #fcfcfc;
	}

	.title {
		background: #24a148;
		min-height: 26px;
		padding: 6px 10px;
	}
}

.card-body.cstm-card-body {
	padding: 20px 20px;
}

.cstm-card-color1 {
	border-left: 10px solid #5066D8;
	border-radius: 4px;
}

.cstm-card-color2 {
	border-left: 10px solid #FFB648;
	border-radius: 4px;
}

.cstm-card-color3 {
	border-left: 10px solid #A284F6;
	border-radius: 4px;
}

.cstm-card-color4 {
	border-left: 10px solid #4BDE97;
	border-radius: 4px;
}

.cstm-card-color5 {
	border-left: 10px solid #F15656;
	border-radius: 4px;
}

.cstm-card-color6 {
	border-left: 10px solid #35B8E0;
	border-radius: 4px;
}

.cstm-accordions-div {
	margin-top: 30px;

	.accordion-header {
		button.accordion-button {
			-webkit-box-shadow: none !important;
			box-shadow: none !important;
			border-radius: 0 !important;
		}
	}

	.accordion {
		.accordion-item {
			border-radius: 0 !important;
			border: none !important;

			.accordion-header {
				border-radius: 0 !important;
			}

			.accordion-collapse.collapse.show {
				.accordion-body {
					border: none !important;
				}
			}
		}
	}
}

.cstm-contact-information-div {
	background-color: $background-color_2;
	padding: 10px 20px;
	margin: 0 -20px;
	height: 55px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;

	h3 {
		margin: 0;
		color: $color_black;
	}
}

.cstm-accordions-finance-view {
	margin-left: 20px;
}

.accordion-header {
	h3 {
		line-height: 0.2;
		font-size: 18px;
		margin-bottom: 0px;
		font-weight: 500;
	}

	.accordion-button {
		&:focus {
			-webkit-box-shadow: none !important;
			box-shadow: none !important;
		}
	}
}

.accordion-button {
	border-radius: 4px 4px 0px 0px;
	background: #f4f4f4;

	&:focus {
		-webkit-box-shadow: none !important;
		box-shadow: none !important;
	}

	&:not(.collapsed) {
		border-radius: 4px 4px 0px 0px;
		background: #f4f4f4;
	}

	&:hover {
		border-radius: 4px 4px 0px 0px;
		background: #f4f4f4;
		color: $color_4;
	}

	&:after {
		border-radius: 4px 4px 0px 0px;
	}
}

.cstm-img-finance-view {
	margin-right: 20px;
}

.heading-finance-view-div {
	color: $color_4;
	font-family: $font-family_1;
	font-size: 18px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
}

.finance-img-with-fileds-div {
	margin-top: 20px;
}

.cstm-edit-btn {
	cursor: pointer;
	-webkit-transition: 0.3s ease-in-out;
	-o-transition: 0.3s ease-in-out;
	transition: 0.3s ease-in-out;
	min-width: 110px;
	font-size: 16px;
	font-weight: 500 !important;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: $background-color_3;
	border-radius: 4px;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	color: $color_white;
	margin: 5px 0 5px 0;

	button.btn {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		justify-content: center;
		font-weight: 500;
		font-family: $font-family_1;
		color: $color_white;
		width: 100%;
		-webkit-transition: 0.3s ease-in-out;
		-o-transition: 0.3s ease-in-out;
		transition: 0.3s ease-in-out;
		padding: 4px;

		svg {
			width: 20px;
		}

		span {
			font-size: 14px;
		}
	}

	&:hover {
		color: $color_white;
		background-color: $background-color_3;

		button.btn {
			color: $color_white;
			background-color: $background-color_3;
		}
	}
}

.error-msg {
	max-width: 500px;
}

.view-finance-button {
	height: 40px;
	padding: 10px;
	background: #58bae4;
	border-radius: 5px;
	border: none;
	color: $color_white;
}

.View-Image {
	width: 100%;
	height: auto;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
}

.vendor-logo {
	img {
		width: 112px;
		height: 43px;
		-o-object-fit: cover;
		object-fit: cover;
		margin-right: 40px;
		border-radius: 2px;
		border: 1px solid #909090;
	}
}